export type BloodKell = "positive" | "dont_know" | "negative";

export type BloodKellType = {
  [s in BloodKell]: string;
};

export const BLOOD_KELLS: BloodKellType = {
  positive: "Положительный",
  negative: "Отрицательный",
  dont_know: "Не знаю",
};

export const BLOOD_KELLS_FILTERS = [
  { value: "positive", title: "Положительный" },
  { value: "negative", title: "Отрицательный" },
  { value: "any", title: "Любой" },
];
