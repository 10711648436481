import { ChoicesWithIconType } from "./common";

export type BloodClass =
  | "blood"
  | "plasma"
  | "platelets"
  | "erythrocytes"
  | "any"
  | "leukocytes"
  | "bloodAndComponents";

export type BloodClassType = {
  [s in BloodClass]?: ChoicesWithIconType;
};

export const BLOOD_CLASES: BloodClassType = {
  blood: {
    title: "Цельная кровь",
    icon: "/static/images/icons/blood.svg",
    not_approved_icon: "/static/images/icons/not_approved_blood.svg",
  },
  plasma: {
    title: "Плазма",
    icon: "/static/images/icons/plasma.svg",
    not_approved_icon: "/static/images/icons/not_approved_plasma.svg",
  },
  platelets: {
    title: "Тромбоциты",
    icon: "/static/images/icons/trombs.svg",
    not_approved_icon: "/static/images/icons/not_approved_trombs.svg",
  },
  erythrocytes: {
    title: "Эритроциты",
    icon: "/static/images/icons/erythrocytes.svg",
    not_approved_icon: "/static/images/icons/not_approved_erythrocytes.svg",
  },
  leukocytes: {
    title: "Гранулоциты",
    icon: "/static/images/icons/leukocytes.svg",
    not_approved_icon: "/static/images/icons/not_approved_leukocytes.svg",
  },
};

export const WAITING_BLOOD_CLASES: BloodClassType = {
  blood: {
    title: "Цельная кровь",
    icon: "/static/images/icons/blood.svg",
    not_approved_icon: "/static/images/icons/not_approved_blood.svg",
  },
  plasma: {
    title: "Плазма",
    icon: "/static/images/icons/plasma.svg",
    not_approved_icon: "/static/images/icons/not_approved_plasma.svg",
  },
  platelets: {
    title: "Тромбоциты",
    icon: "/static/images/icons/trombs.svg",
    not_approved_icon: "/static/images/icons/not_approved_trombs.svg",
  },
  erythrocytes: {
    title: "Эритроциты",
    icon: "/static/images/icons/erythrocytes.svg",
    not_approved_icon: "/static/images/icons/not_approved_erythrocytes.svg",
  },
  leukocytes: {
    title: "Гранулоциты",
    icon: "/static/images/icons/leukocytes.svg",
    not_approved_icon: "/static/images/icons/not_approved_leukocytes.svg",
  },
  any: {
    title: "Все компоненты",
    icon: "/static/images/icons/allComponents.svg",
  },
};
