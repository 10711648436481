export type BloodGroup =
  | "o_plus"
  | "o_minus"
  | "a_plus"
  | "a_minus"
  | "b_plus"
  | "b_minus"
  | "ab_plus"
  | "dont_know"
  | "any"
  | "ab_minus";

export type BloodGroupType = {
  [s in BloodGroup]?: string;
};

export const BLOOD_GROUPS: BloodGroupType = {
  o_plus: "Первая положительная — 0(I) Rh+",
  o_minus: "Первая отрицательная — 0(I) Rh-",
  a_plus: "Вторая положительная — A(II) Rh+",
  a_minus: "Вторая отрицательная — A(II) Rh-",
  b_plus: "Третья положительная — B(III) Rh+",
  b_minus: "Третья отрицательная — B(III) Rh-",
  ab_plus: "Четвертая положительная — AB(IV) Rh+",
  ab_minus: "Четвертая отрицательная — AB(IV) Rh-",
  dont_know: "Не знаю",
};

export const SHORT_BLOOD_GROUPS_HELP_ME: BloodGroupType = {
  o_plus: "0 (I) Rh+",
  o_minus: "0 (I) Rh-",
  a_plus: "A (II) Rh+",
  a_minus: "A (II) Rh-",
  b_plus: "B (III) Rh+",
  b_minus: "B (III) Rh-",
  ab_plus: "AB (IV) Rh+",
  ab_minus: "AB (IV) Rh-",
};

export const SHORT_BLOOD_GROUPS: BloodGroupType = {
  o_plus: "0 (I) Rh+",
  a_plus: "A (II) Rh+",
  b_plus: "B (III) Rh+",
  ab_plus: "AB (IV) Rh+",
  o_minus: "0 (I) Rh-",
  a_minus: "A (II) Rh-",
  b_minus: "B (III) Rh-",
  ab_minus: "AB (IV) Rh-",
};

export const VERY_SHORT_BLOOD_GROUPS: BloodGroupType = {
  o_plus: "0+",
  a_plus: "A+",
  b_plus: "B+",
  ab_plus: "AB+",
  o_minus: "0-",
  a_minus: "A-",
  b_minus: "B-",
  ab_minus: "AB-",
};

export const BLOOD_WAITING: BloodGroupType = {
  o_plus: "Первая положительная — 0(I) Rh+",
  o_minus: "Первая отрицательная — 0(I) Rh-",
  a_plus: "Вторая положительная — A(II) Rh+",
  a_minus: "Вторая отрицательная — A(II) Rh-",
  b_plus: "Третья положительная — B(III) Rh+",
  b_minus: "Третья отрицательная — B(III) Rh-",
  ab_plus: "Четвертая положительная — AB(IV) Rh+",
  ab_minus: "Четвертая отрицательная — AB(IV) Rh-",
  any: "Все компоненты",
};

export const SHORT_BLOOD_WAITING: BloodGroupType = {
  o_plus: "0(I) Rh+",
  o_minus: "0(I) Rh-",
  a_plus: "A(II) Rh+",
  a_minus: "A(II) Rh-",
  b_plus: "B(III) Rh+",
  b_minus: "B(III) Rh-",
  ab_plus: "AB(IV) Rh+",
  ab_minus: "AB(IV) Rh-",
  any: "Любая",
};

export const SHORT_TEXT_BLOOD_WAITING: BloodGroupType = {
  o_plus: "Первая положительная",
  o_minus: "Первая отрицательная",
  a_plus: "Вторая положительная",
  a_minus: "Вторая отрицательная",
  b_plus: "Третья положительная",
  b_minus: "Третья отрицательная",
  ab_plus: "Четвертая положительная",
  ab_minus: "Четвертая отрицательная",
};
