import {DonationTypeWithText} from './donation_common';

export type DonationTypeGroup =
  | 'free'
  | 'payed';

export type DonationTypeGroupType = {
  [s in DonationTypeGroup]: DonationTypeWithText;
}

export const DONATION_TYPES: DonationTypeGroupType = {
  free: {main: 'Безвозмездно', second: 'Питание или компенсация питания (5% МРОТ порядка 700-1500 ₽. Учитывается при получении звания Почетного донора.)'},
  payed: {main: 'Платно', second: 'Деньги или социальная поддержка. Не учитывается при получении звания почетного донора'},
};
